import React, { Fragment } from 'react';

import StudioIntro from './StudioIntro';
import StudioClients from './StudioClients';
import StudioCapabilities from './StudioCapabilities';
import StudioAwards from './StudioAwards';
import StudioTeam from './StudioTeam';
import StudioProcess from './StudioProcess';

const Studio = () => (
    <Fragment>
        <StudioIntro />
        <StudioClients />
        <StudioCapabilities />
        <StudioAwards />
        <StudioTeam />
        <StudioProcess />
    </Fragment>
);

export default Studio;
