import React, { Fragment, useContext } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import { AppContext } from 'features/app';

import SingleIntro from './SingleIntro';
import SingleContent from './SingleContent';
import SingleImage from './SingleImage';
import SingleGrid from './SingleGrid';
import SingleNext from './SingleNext';

const WorkDetails = ({ linkClick }) => {
    const works = useContext(AppContext).works;

    const { slug } = useParams();
    const work = works.find(({ slug: itemSlug }) => slug === itemSlug);
    if (typeof work === 'undefined') {
        return <Redirect to="/work" />;
    }

    return (
        <Fragment>
            <SingleIntro work={work} />
            <SingleContent work={work} />
            <SingleImage work={work} field="grid_image_top" />
            <SingleGrid work={work} />
            <SingleImage work={work} field="grid_image_bottom" />
            <SingleNext work={work} linkClick={linkClick} />
        </Fragment>
    );
};

export default WorkDetails;
