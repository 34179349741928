import React, { useEffect, useRef, useState } from 'react';

import { ClientsSlider } from 'features/common';

const HomeClients = () => {
    const refHeader = useRef(null);
    const refTitle = useRef(null);
    const refContainer = useRef(null);
    const [titlePercent, setTitlePercent] = useState(0);
    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('resize', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('resize', onScroll);
        };
    });

    const onScroll = () => {
        if (!refHeader.current || !refTitle.current) {
            return;
        }

        const headerBox = refHeader.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const startTop = windowHeight - headerBox.height / 2;
        const endTop = (-1 * headerBox.height) / 2;

        let percent = 0;
        if (headerBox.top > startTop) {
            percent = 0;
        } else if (headerBox.top <= startTop && headerBox.top >= endTop) {
            percent = 1 - (headerBox.top - endTop) / (startTop - endTop);
        } else {
            percent = 1;
        }

        setTitlePercent(percent);
    };

    const titleStyle = {
        transform: `translate(${titlePercent * -130}rem, 0)`,
    };

    return (
        <div className="container-home-clients" ref={refContainer}>
            <div className="page-home-clients">
                <header className="home-clients-header" ref={refHeader}>
                    <h3 className="title" ref={refTitle} style={titleStyle}>
                        We work as one.
                    </h3>
                    <p className="desc">
                        We pride ourselves on providing our industry-leading services to a diverse client base. Small,
                        midsize, and large businesses. We have the expertise to facilitate each client for their unique
                        individual business needs
                    </p>
                </header>
                <ClientsSlider />
            </div>
        </div>
    );
};

export default HomeClients;
