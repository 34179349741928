import { useEffect, useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';

const calculateTime = () => {
    const date = new Date();
    const nyTimeZone = 'America/New_York';
    const nyDate = utcToZonedTime(date, nyTimeZone);
    return format(nyDate, 'hh:mm a', { timeZone: 'America/New_York' }).toLowerCase();
};

const useTime = () => {
    const [time, setTime] = useState(calculateTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(calculateTime());
        }, 30 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return time;
};

export default useTime;
