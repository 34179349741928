import React, { Fragment } from 'react';
import cx from 'classnames';

const Cover = ({ state: { active, step, text }, progress }) => {
    const transitionEndEvent = (e) => {
        if (e.target.classList.contains('enter') || e.target.classList.contains('leave')) {
            progress();
        }
    };

    const classStart = cx('page-cover', 'page-cover-start', {
        enter: active && step === 0,
        leave: active && step === 1,
    });
    const classEnd = cx('page-cover', 'page-cover-end', {
        enter: active && step === 2,
        leave: active && step === 3,
    });
    const classText = cx('page-cover', 'page-cover-text', {
        enter: active && step === 0,
        stay: active && (step === 1 || step === 2),
        leave: active && step === 3,
    });

    return (
        <Fragment>
            <div className={classStart} onTransitionEnd={transitionEndEvent} />
            <div className={classText}>
                <span>{text}</span>
            </div>
            <div className={classEnd} onTransitionEnd={transitionEndEvent} />
        </Fragment>
    );
};

export default Cover;
