import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

import { useTime } from 'hooks-global';
import mapStyles from '../data/mapStyles';

const ContactHeader = () => {
    const time = useTime();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDgcAXUB2h2ezMOFcU9OPkFdUHsqrhhv-k',
    });
    const [mapMarkerIcon, setMapMarkerIcon] = useState({});

    const mapCenter = {
        lat: 40.75595,
        lng: -73.99207,
    };

    const mapOptions = {
        disableDefaultUI: true,
        styles: mapStyles,
    };

    useEffect(() => {
        if (isLoaded) {
            const mapMarkerSvg =
                '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" style="fill:#fff"/></svg>';
            setMapMarkerIcon({
                url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(mapMarkerSvg)}`,
                size: new window.google.maps.Size(16, 16),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(8, 8),
                scaledSize: new window.google.maps.Size(16, 16),
            });
        }
    }, [isLoaded]);

    return (
        <div className="container-contact-header">
            <div className="layout">
                <div className="page-contact-header">
                    <div className="contact-header-content">
                        <h1 className="contact-header-title">
                            Taktic Studio is located in Manhattan’s Garment District.
                        </h1>
                        <div className="contact-header-company">
                            <address className="contact-header-address">
                                <a href="https://g.page/taktic" target="_blank" rel="noopener noreferrer">
                                    <span className="contact-header-link">315 West 39TH Street, Studio 609</span>
                                    <br />
                                    <span className="contact-header-link">New York City, NY 10018</span>
                                </a>
                            </address>
                            <div className="contact-header-phone-email">
                                <a className="contact-header-link" href="tel:+1 212 804 8106">
                                    +1 212 804 8106
                                </a>
                                <br />
                                <a className="contact-header-link" href="mailto:info@takticstudio.com">
                                    info(at)takticstudio.com
                                </a>
                            </div>
                            <div className="contact-header-hours">
                                nyc time: {time}
                                <br />
                                studio hours: 8am - 6pm / mon - sat
                                <br />
                                website: 24/7
                            </div>
                        </div>
                    </div>
                    <div className="contact-header-map">
                        {isLoaded && (
                            <GoogleMap center={mapCenter} zoom={14} options={mapOptions}>
                                <Marker position={mapCenter} icon={mapMarkerIcon} name="Taktic Studio" />
                            </GoogleMap>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactHeader;
