import React, { Fragment } from 'react';

import ListIntro from './ListIntro';
import List from './List';

const Work = ({ linkClick }) => {
    return (
        <Fragment>
            <ListIntro />
            <List linkClick={linkClick} />
        </Fragment>
    );
};

export default Work;
