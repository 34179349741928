import React, { useContext, useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { useDrag } from 'react-use-gesture';

import { AppContext } from 'features/app';

const HomeIntro = () => {
    const data = useContext(AppContext).home_intro;

    const [dataIndex, setDataIndex] = useState(0);
    const [isScrollable, setIsScrollable] = useState(false);

    const isAnimating = useRef(false);
    const isAnimatingTimeout = useRef(null);

    /* useEffect(() => {
        let retries = 0;
        const interval = setInterval(() => {
            let allPlaying = true;
            for (let i = 0; i < refVideos.length; i++) {
                if (!refVideos[i].current || refVideos[i].current.paused || refVideos[i].current.readyState <= 2) {
                    allPlaying = false;
                }
            }

            if (allPlaying) {
                clearInterval(interval);
            } else {
                retries++;
                for (let i = 0; i < refVideos.length; i++) {
                    if (refVideos[i].current) {
                        if (navigator.userAgent !== 'ReactSnap') {
                            refVideos[i].current.muted = true;
                            refVideos[i].current.play();
                        }
                    }
                }
            }

            if (retries > 10) {
                clearInterval(interval);
            }
        }, 500);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, []); */

    useEffect(() => {
        if (isScrollable === true) {
            document.body.classList.remove('body-fixed');
            window.removeEventListener('wheel', onScroll, false);
            clearTimeout(isAnimatingTimeout.current);
        } else {
            window.scrollTo(0, 0);
            document.body.classList.add('body-fixed');
            window.addEventListener('wheel', onScroll, false);
        }
        return () => {
            document.body.classList.remove('body-fixed');
            window.removeEventListener('wheel', onScroll, false);
        };
        // eslint-disable-next-line
    }, [dataIndex, isScrollable]);

    useEffect(() => {
        return () => {
            clearTimeout(isAnimatingTimeout.current);
        };
        // eslint-disable-next-line
    }, []);

    const onScroll = (e) => {
        if (isAnimating.current === true || e.deltaY === 0) {
            return;
        }

        isAnimating.current = true;
        let nextDataIndex = dataIndex;
        if (e.deltaY > 0) {
            nextDataIndex = dataIndex + 1 > data.length - 1 ? data.length - 1 : dataIndex + 1;
        } else if (e.deltaY < 0) {
            nextDataIndex = dataIndex - 1 < 0 ? 0 : dataIndex - 1;
        }
        setDataIndex(nextDataIndex);

        if (dataIndex === data.length - 1) {
            setIsScrollable(true);
        }

        isAnimatingTimeout.current = setTimeout(() => {
            isAnimating.current = false;
        }, 2000);
    };

    const drag = useDrag(({ movement }) => {
        onScroll({ deltaY: -movement[1] });
    });

    return (
        <div className={cx('container-home-intro', { scrollable: isScrollable })} {...drag()}>
            <div className="home-intro-video-box">
                {data.map(({ id, poster, video }, index) => {
                    const classes = cx('home-intro-video', {
                        hide: index >= data.length - 1 ? false : index < dataIndex ? true : false,
                        active: index === dataIndex,
                    });
                    return (
                        <div className={classes} key={id}>
                            <video src={video} poster={poster} muted autoPlay playsInline loop />
                        </div>
                    );
                })}
            </div>
            <div className="home-intro-image-box">
                {data.map(({ id, desc, image }, index) => {
                    const imageClasses = cx('home-intro-image', {
                        hide: index >= data.length - 1 ? false : index < dataIndex ? true : false,
                        active: index === dataIndex,
                    });
                    return (
                        <div className={imageClasses} key={id}>
                            <div className="home-intro-image-content">
                                <h2>{desc}</h2>
                                <img src={image} alt={desc} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HomeIntro;
