import React, { useState, useRef, useContext } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useDrag } from 'react-use-gesture';

import { AppContext } from 'features/app';

const HomeContact = ({ linkClick }) => {
    const slides = useContext(AppContext).home_contact;

    const refSlider = useRef(null);
    const [slideIndex, setSlideIndex] = useState(0);
    const prevSlideIndex = slideIndex === 0 ? slides.length - 1 : slideIndex - 1;
    const nextSlideIndex = slideIndex === slides.length - 1 ? 0 : slideIndex + 1;
    const nextNextSlideIndex = nextSlideIndex === slides.length - 1 ? 0 : nextSlideIndex + 1;
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [cursorActive, setCursorActive] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const isMobile = window.matchMedia('(max-width: 576px)').matches;

    const handleNextClick = () => {
        if (isAnimating) {
            return;
        }

        setIsAnimating(true);

        if (slideIndex + 1 >= slides.length) {
            setSlideIndex(0);
        } else {
            setSlideIndex(slideIndex + 1);
        }
    };

    const transitionEndEvent = () => {
        setIsAnimating(false);
    };

    const handleMouseEnter = () => setCursorActive(true);
    const handleMouseLeave = () => setCursorActive(false);
    const handleMouseMove = (e) => {
        if (!refSlider.current) {
            return;
        }
        const rect = refSlider.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setCursorPosition({ x, y });
    };

    const drag = useDrag(({ movement }) => {
        if (movement[0] > 0) {
            handleNextClick();
        }
    });

    return (
        <div className="container-home-contact">
            <div className="page-home-contact">
                {isMobile && (
                    <ul className={`home-contact-dots home-contact-dots-${slides.length}`}>
                        {slides.map((slideData, index) => (
                            <li
                                key={index}
                                className={cx('home-contact-dot', {
                                    active: slideIndex === index,
                                })}
                            />
                        ))}
                    </ul>
                )}
                <div className="home-contact-content">
                    <h3 className="title">Ready? Say hi</h3>
                    <p className="desc">
                        If you’re looking for a new creative partner for your project, we’d love to talk. Send us a
                        message.
                    </p>
                    <Link to="/contact/" className="link pointer-events-disable" onClick={linkClick('Contact')}>
                        Message
                    </Link>
                </div>
            </div>
            <div className="home-contact-slider-next">
                {slides.map(({ id, title, image, image_2x }, index) => (
                    <img
                        key={id}
                        src={image}
                        srcSet={`${image} 1x, ${image_2x} 2x`}
                        alt={title}
                        className={cx('home-contact-slide-next', {
                            current: nextSlideIndex === index,
                            next: nextNextSlideIndex === index,
                            prev: slideIndex === index,
                        })}
                    />
                ))}
            </div>
            <div className="home-contact-slider-current">
                {slides.map(({ id, title, image, image_2x }, index) => (
                    <img
                        key={id}
                        src={image}
                        srcSet={`${image} 1x, ${image_2x} 2x`}
                        alt={title}
                        className={cx('home-contact-slide-current', {
                            current: slideIndex === index,
                            next: nextSlideIndex === index,
                            prev: prevSlideIndex === index,
                        })}
                        onTransitionEnd={slideIndex === index ? transitionEndEvent : null}
                    />
                ))}
                <div
                    className="home-contact-cursor-box"
                    onClick={handleNextClick}
                    onMouseMove={handleMouseMove}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={refSlider}
                    {...drag()}
                >
                    <div
                        className={cx('home-contact-next', { active: cursorActive })}
                        style={{ transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)` }}
                    >
                        <span>Next</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeContact;
