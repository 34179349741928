import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { InputText, InputTextarea } from 'features/common';

const emptyError = {
    name: false,
    phone: false,
    email: false,
    message: false,
};

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [sending, setSending] = useState(false);
    const [error, setError] = useState({ ...emptyError });
    const [responseType, setResponseType] = useState(null);

    const errorAny = Object.keys(error).reduce((acc, key) => acc || error[key], false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const error = { ...emptyError };

        error.name = name === '';
        const emailRegExp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/; // eslint-disable-line max-len
        error.email = !emailRegExp.test(email);
        error.phone = phone === '';
        error.message = message === '';

        const errorAny = Object.keys(error).reduce((acc, key) => error[key] || acc, false);
        if (errorAny === false) {
            setSending(true);

            try {
                const response = await axios({
                    method: 'post',
                    url: `${process.env.PUBLIC_URL}/code/form-contact.php`,
                    data: {
                        name: name.substring(0, 50),
                        phone: phone.substring(0, 20),
                        email: email.substring(0, 100),
                        message: message.substring(0, 10000),
                    },
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                });

                if (response.data && response.data.success === true) {
                    setName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                    setError({ ...emptyError });
                    setSending(false);
                    setResponseType('success');

                    setTimeout(() => {
                        setResponseType(null);
                    }, 5000);
                } else {
                    setError({
                        name: !!response.data.message.name,
                        phone: !!response.data.message.phone,
                        email: !!response.data.message.email,
                        message: !!response.data.message.message,
                    });
                    setSending(false);
                    setResponseType('error');
                }
            } catch (err) {
                console.log(err);
                setError({ ...emptyError });
                setSending(false);
                setResponseType('error');
            }
        } else {
            setError(error);
            setResponseType('error');
        }
    };

    return (
        <div className="container-contact-form">
            <div className="layout-contact-form">
                {responseType === 'success' && (
                    <div className="contact-form">
                        <h2 className="contact-form-title contact-form-title--center">Done & Done</h2>
                        <p className="contact-form-text">
                            We have received your message and would like to thank you for writing to us. We will reply
                            by email as soon as possible.
                            <br />
                            Cheers!
                        </p>
                        <div className="contact-form-image">
                            <img src={require('../assets/success.svg')} alt="Done & Done" />
                        </div>
                        <div className="contact-form-button">
                            <Link to="/">Return to homepage</Link>
                        </div>
                    </div>
                )}
                {responseType !== 'success' && (
                    <form action="" method="post" className="contact-form" noValidate onSubmit={handleSubmit}>
                        <h2 className="contact-form-title">Drop us a line.</h2>
                        <InputText
                            name="name"
                            id="contact-name"
                            label="Name"
                            value={name}
                            setFunc={setName}
                            error={error.name}
                        />
                        <InputText
                            type="email"
                            name="email"
                            id="contact-email"
                            label="Email"
                            value={email}
                            setFunc={setEmail}
                            error={error.email}
                        />
                        <InputText
                            name="phone"
                            id="contact-phone"
                            label="Phone"
                            value={phone}
                            setFunc={setPhone}
                            error={error.phone}
                        />
                        <InputTextarea
                            name="message"
                            id="contact-message"
                            label="In a few words, please tell us about your request."
                            value={message}
                            setFunc={setMessage}
                            error={error.message}
                        />
                        <div className="contact-form-button">
                            <button type="submit" disabled={sending || responseType === 'success'}>
                                Send Message
                            </button>
                        </div>
                        {(responseType === 'error' || errorAny) && (
                            <div className="contact-form-message error">
                                <span>One or more fields have an error. Please check and try again.</span>
                            </div>
                        )}
                    </form>
                )}
            </div>
        </div>
    );
};

export default ContactForm;
