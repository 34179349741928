import React from 'react';

const ListIntro = () => {
    return (
        <div className="container-works-intro">
            <div className="page-works-intro">
                <h1 className="works-intro-title">Our Projects</h1>
                <p className="works-intro-desc">
                    We are true believers in bold ideas supported by simple and timeless design. Simple always works.
                </p>
            </div>
        </div>
    );
};

export default ListIntro;
