import React, { Fragment, useState, useReducer, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { Header, HeaderFixed, Menu } from 'features/header';
import { Footer } from 'features/footer';
import { Cover } from 'features/cover';

import { Home } from 'features/home';
import { Contact } from 'features/contact';
import { Studio } from 'features/studio';
import { Work, WorkDetails } from 'features/work';

import ResizeVh from './ResizeVh';
import ScrollToTop from './ScrollToTop';

const Content = () => {
    const history = useHistory();

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const menuOpen = () => setMenuIsOpen(true);
    const menuClose = () => setMenuIsOpen(false);

    const coverInitialState = {
        active: false,
        step: 0,
        url: null,
        text: '',
    };

    const coverReducer = (state, action) => {
        switch (action.type) {
            case 'start':
                return {
                    active: true,
                    step: 0,
                    url: action.payload.url,
                    text: action.payload.text,
                };
            case 'progress':
                if (state.step < 3) {
                    return {
                        ...state,
                        step: state.step + 1,
                    };
                }
                return {
                    ...coverInitialState,
                };
            default:
                throw new Error();
        }
    };
    const [coverState, coverDispatch] = useReducer(coverReducer, coverInitialState);
    useEffect(() => {
        if (coverState.step === 1) {
            menuClose();
        }
        if (coverState.step === 2) {
            history.push(coverState.url);
        }
    }, [coverState.step, coverState.url, history]);

    const linkClick = (text) => (e) => {
        e.preventDefault();
        if (coverState.active) {
            return;
        }
        window.scrollTo(0, 0);

        const url = e.target.getAttribute('href');
        coverDispatch({ type: 'start', payload: { url, text } });
    };

    const coverProgress = () => coverDispatch({ type: 'progress' });

    return (
        <Fragment>
            <ResizeVh />
            <ScrollToTop />
            <Cover state={coverState} progress={coverProgress} />
            <Header menuOpen={menuOpen} linkClick={linkClick} />
            <HeaderFixed menuOpen={menuOpen} linkClick={linkClick} />
            <Menu linkClick={linkClick} close={menuClose} isOpen={menuIsOpen} />
            <Switch>
                <Route exact path="/">
                    <Home linkClick={linkClick} />
                </Route>
                <Route exact path="/contact/">
                    <Contact />
                </Route>
                <Route exact path="/studio/">
                    <Studio />
                </Route>
                <Route exact path="/work/">
                    <Work linkClick={linkClick} />
                </Route>
                <Route path="/work/:slug/">
                    <WorkDetails linkClick={linkClick} />
                </Route>
            </Switch>
            <Footer />
        </Fragment>
    );
};

export default Content;
