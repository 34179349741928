import React from 'react';

const SingleImage = ({ field, work }) => {
    if (typeof work[field] === 'undefined') {
        return null;
    }

    return (
        <div className="conteiner-works-single-image">
            <img className="works-single-image" src={work[field]} alt={work.title} />
        </div>
    );
};

export default SingleImage;
