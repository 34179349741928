import React from 'react';
import cx from 'classnames';
import { useRouteMatch, Link } from 'react-router-dom';

import Logo from './Logo';

const Header = ({ menuOpen, linkClick }) => {
    const isRouteHome = !!useRouteMatch({ path: '/', exact: true, strict: true });
    const isRouteStudio = !!useRouteMatch({ path: '/studio/', exact: true, strict: true });
    const isDesktop = window.matchMedia('(min-width: 961px)').matches;

    const isLogoDark = isRouteStudio || (isRouteHome && isDesktop);
    const isTriggerDark = isRouteStudio;

    return (
        <div className="header-box header-top">
            <div className={cx('header-logo', { dark: isLogoDark })}>
                <Link to="/" onClick={linkClick('Home')} className="pointer-events-disable">
                    <Logo alt="Taktic Studio" />
                </Link>
            </div>
            <div className={cx('header-trigger', { dark: isTriggerDark })} onClick={menuOpen} />
        </div>
    );
};

export default Header;
