import React from 'react';

import { ClientsSlider } from 'features/common';

const StudioClients = () => (
    <div className="container-studio-clients">
        <div className="page-studio-clients">
            <header className="studio-clients-header">
                <h3>
                    Selected
                    <br /> Clients List
                </h3>
            </header>
            <div className="studio-clients-content">
                <p>
                    We pride ourselves on providing our industry-leading services to a diverse client base. We have
                    worked with small, midsize, and large businesses. It doesn’t matter if you’re a Fortune 500
                    uber-corporation or a small startup business; we have the expertise to facilitate each client for
                    their unique individual business needs.
                </p>
                <ul>
                    <li>Loreal</li>
                    <li>Carol’s Daughter</li>
                    <li>D’Addario</li>
                    <li>Darby Dental</li>
                    <li>Georgia Pacific</li>
                    <li>Mahntra</li>
                    <li>Promark</li>
                    <li>Hudson Bread</li>
                    <li>Statar Capital</li>
                    <li>Carol’s Daughter</li>
                    <li>D’Addario</li>
                    <li>Darby Dental</li>
                    <li>Georgia Pacific</li>
                    <li>Mahntra</li>
                    <li>Promark</li>
                    <li>Hudson Bread</li>
                </ul>
            </div>
            <ClientsSlider />
            <footer className="studio-clients-footer">
                <p>
                    We aim to create a strong, forward-thinking brand identity that empowers our clients to stand out
                    from the crowd. We believe in building long term relationships with a diverse range of clients by
                    bringing eloquent design experiences to life. Our website design and development services ensure
                    that the business and its vision are properly represented.
                </p>
            </footer>
        </div>
    </div>
);

export default StudioClients;
