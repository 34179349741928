import React from 'react';

const Arrow = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 24" {...props}>
        <polygon
            points="13.56 6.57 14 7.01 13.12 7.88 7.62 2.38 7.62 24 6.38 24 6.38 2.38 0.88 7.88 0 7.01 7 0 13.56 6.57"
            fill="#cfd1d1"
        />
    </svg>
);

export default Arrow;
