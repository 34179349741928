import React, { useContext, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { AppContext } from 'features/app';
import Arrow from '../assets/Arrow';

const StudioCapability = ({ title, desc, elements, clickItem, active }) => {
    const refContainer = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('resize', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('resize', onScroll);
        };
    });

    const onScroll = () => {
        const vh = parseFloat(document.documentElement.style.getPropertyValue('--vh'));
        if (!refContainer.current || Number.isNaN(vh)) {
            return;
        }

        const containerBox = refContainer.current.getBoundingClientRect();
        if (containerBox.top + containerBox.height < 100 * vh) {
            setVisible(true);
        }
    };

    return (
        <div key={title} ref={refContainer} className={cx('studio-capabilities-section', { visible, active })}>
            <div className="studio-capabilities-content">
                <h3>{title}</h3>
                <p>{desc}</p>
                <div className="more" onClick={clickItem}>
                    <Arrow />
                </div>
            </div>
            <ul className="studio-capabilities-list">
                {elements.map(({ name }, listIndex) => (
                    <li key={listIndex}>{name}</li>
                ))}
            </ul>
        </div>
    );
};

const StudioCapabilities = () => {
    const capabilities = useContext(AppContext).capabilities;
    const [active, setActive] = useState(null);

    const clickItem = (index) => () => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    };

    return (
        <div className="container-studio-capabilities">
            <div className="layout">
                <section className="page-studio-capabilities">
                    {capabilities.map((capability, index) => (
                        <StudioCapability
                            key={index}
                            clickItem={clickItem(index)}
                            active={active === index}
                            {...capability}
                        />
                    ))}
                </section>
            </div>
        </div>
    );
};

export default StudioCapabilities;
