import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import Logo from './Logo';

const HeaderFixed = ({ linkClick, menuOpen }) => {
    const [visible, setVisible] = useState(false);
    const [prevScroll, setPrevScroll] = useState(0);

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('resize', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('resize', onScroll);
        };
    });

    const onScroll = () => {
        const windowHeight = window.innerHeight;
        const scrollTop = window.pageYOffset;
        const scrollDiff = prevScroll - scrollTop;

        let visible = false;
        if (scrollTop > windowHeight && scrollDiff > 0) {
            visible = true;
        }

        setVisible(visible);
        setPrevScroll(scrollTop);
    };

    return (
        <div className={cx('header-box', 'header-fixed', { visible })}>
            <div className="header-logo dark">
                <Link to="/" onClick={linkClick('Home')} className="pointer-events-disable">
                    <Logo alt="Taktic Studio" />
                </Link>
            </div>
            <div className="header-trigger dark" onClick={menuOpen} />
        </div>
    );
};

export default HeaderFixed;
