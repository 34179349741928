import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { AppContext } from 'features/app';

const List = ({ linkClick }) => {
    const works = useContext(AppContext).works;

    return (
        <div className="container-works-list">
            <div className="layout">
                <div className="page-works-list">
                    {works.map(({ slug, title, tags, list_size, list_image, list_cover_color }) => (
                        <Link
                            to={`/work/${slug}/`}
                            className={cx('works-list-item', 'pointer-events-disable', `works-list-item-${list_size}`)}
                            key={slug}
                            onClick={linkClick('Work')}
                        >
                            <img className="works-list-image" src={list_image} alt={title} />
                            <div className="works-list-cover" style={{ backgroundColor: list_cover_color }} />
                            <div className="works-list-content">
                                <h3 className="works-list-name">{title}</h3>
                                <p className="works-list-tags">
                                    {tags.map((tag, i) => (
                                        <Fragment key={i}>
                                            <span key={i} className="tag-name">
                                                {tag}
                                            </span>
                                            {i < tags.length - 1 && <span className="tag-slash">/</span>}
                                        </Fragment>
                                    ))}
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default List;
