import React, { useRef, useEffect, useState, useContext } from 'react';

import { AppContext } from 'features/app';

const ClientsSlider = () => {
    const clients = useContext(AppContext).clients;

    const refScroll = useRef(null);
    const [sliderPercent, setSliderPercent] = useState(0);
    const isTablet = window.matchMedia('(max-width: 577px and max-width: 960px)').matches;
    const isMobile = window.matchMedia('(max-width: 576px)').matches;

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('resize', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('resize', onScroll);
        };
    });

    const onScroll = () => {
        if (!refScroll.current && !isMobile) {
            return;
        }

        const stepsCount = clients.length;
        const scrollBox = refScroll.current.getBoundingClientRect();
        const scrollProgress = -1 * scrollBox.top;
        const scrollHeight = scrollBox.height;
        const contentHeight = scrollHeight / stepsCount;
        const scrollMax = scrollHeight - contentHeight;

        let percent = 0;
        if (scrollProgress < 0) {
            percent = 0;
        } else if (scrollProgress >= 0 && scrollProgress <= scrollMax) {
            percent = scrollProgress / scrollMax;
        } else {
            percent = 1;
        }

        setSliderPercent(percent);
    };

    const fontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    let elementWidthRem = 23.2 + 1.2;
    if (isTablet) {
        elementWidthRem = 16 + 1.2;
    } else if (isMobile) {
        elementWidthRem = 15.4 + 1.2;
    }

    const listWidthRem = elementWidthRem * (clients.length - 1) - 1.2; // minus last margin
    const listWidth = listWidthRem * fontSize - window.innerWidth;
    const position = -1 * listWidth * sliderPercent;
    const listStyle = isMobile ? {} : { transform: `translate(${position}px, 0)` };
    const containerStyle = isMobile ? {} : { height: `${23.2 * clients.length}rem` };

    return (
        <div className="clients-slider-container" ref={refScroll} style={containerStyle}>
            <div className="clients-slider">
                <div className="clients-slider-list" style={listStyle}>
                    {clients.map(({ type, ...params }, index) => {
                        return (
                            <div className={`clients-slider-item clients-slider-item--${type}`} key={index}>
                                {type === 'testimonial' && <p>{params.quote}</p>}
                                {type === 'client' && (
                                    <img
                                        src={params.logo}
                                        srcSet={`${params.logo} 1x, ${params.logo_2x} 2x`}
                                        alt={params.title}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ClientsSlider;
