import React, { useState } from 'react';
import cx from 'classnames';

const InputText = ({ name, id, label, value, setFunc, error = null }) => {
    const [focus, setFocus] = useState(false);
    const handleFocus = () => {
        setFocus(true);
    };
    const handleBlur = () => {
        setFocus(false);
    };
    const handleChange = (e) => {
        setFunc(e.target.value);
    };

    return (
        <div className={cx('input-text', { focus, 'not-empty': value !== '', error })}>
            <label htmlFor={id}>{label}</label>
            <div className="input-box">
                <textarea
                    className="input-field"
                    name={name}
                    id={id}
                    value={value}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </div>
            {error !== null && <div className="input-error">{error}</div>}
        </div>
    );
};

export default InputText;
