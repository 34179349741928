import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

const HomeAbout = () => {
    const refContainer = useRef(null);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('resize', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('resize', onScroll);
        };
    });

    const onScroll = () => {
        const vh = parseFloat(document.documentElement.style.getPropertyValue('--vh'));
        if (!refContainer.current || Number.isNaN(vh)) {
            return;
        }

        const containerBox = refContainer.current.getBoundingClientRect();
        if (containerBox.top + containerBox.height < 100 * vh) {
            setVisible(true);
        }
    };

    return (
        <div className="container-home-about" ref={refContainer}>
            <div className={cx('page-home-about', { visible })}>
                <div className="home-about-line">Taktic Studio is an independent graphic design</div>
                <div className="home-about-line">studio located in Manhattan’s Garment District</div>
                <div className="home-about-line">that specializes in website design and creative</div>
                <div className="home-about-line">development with an emphasis on branding.</div>
                <div className="home-about-line break">We’re dedicated to creating impactful brands and</div>
                <div className="home-about-line">helping them unlock their true potential.</div>
            </div>
        </div>
    );
};

export default HomeAbout;
