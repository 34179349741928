import React, { Fragment } from 'react';

import HomeIntro from './HomeIntro';
import HomeAbout from './HomeAbout';
import HomeWorks from './HomeWorks';
import HomeCapabilities from './HomeCapabilities';
import HomeClients from './HomeClients';
import HomeContact from './HomeContact';

const Home = ({ linkClick }) => (
    <Fragment>
        <HomeIntro />
        <HomeAbout />
        <HomeWorks linkClick={linkClick} />
        <HomeCapabilities />
        <HomeClients />
        <HomeContact linkClick={linkClick} />
    </Fragment>
);

export default Home;
