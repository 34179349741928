import React, { Fragment } from 'react';

const SingleIntro = ({ work: { title, description, intro_color_bg, intro_color_text, intro_image } }) => {
    return (
        <Fragment>
            <div className="container-works-single-intro">
                <div className="page-works-single-intro">
                    <div
                        className="works-single-intro-content"
                        style={{ color: intro_color_text, backgroundColor: intro_color_bg }}
                    >
                        <h1 className="works-single-intro-title">{title}</h1>
                        <p className="works-single-intro-desc">{description}</p>
                    </div>
                    <img className="works-single-intro-image" src={intro_image} alt={title} />
                </div>
            </div>
        </Fragment>
    );
};

export default SingleIntro;
