import React, { useContext } from 'react';

import { AppContext } from 'features/app';

const StudioTeam = () => {
    const team = useContext(AppContext).team;

    return (
        <div className="container-studio-team">
            <div className="layout">
                <section className="page-studio-team">
                    {team.map(({ id, title, occupation, image, image_2x }) => (
                        <div className="studio-team-person" key={id}>
                            <img src={image} srcSet={`${image} 1x, ${image_2x} 2x`} alt="Roman" />
                            <div className="name">{title}</div>
                            <div className="occupation">{occupation}</div>
                        </div>
                    ))}
                </section>
            </div>
        </div>
    );
};

export default StudioTeam;
