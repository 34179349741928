import React, { useRef, useState, useContext } from 'react';
import cx from 'classnames';
import { useDrag } from 'react-use-gesture';

import { AppContext } from 'features/app';

const StudioIntro = () => {
    const slides = useContext(AppContext).studio_intro;

    const refImages = useRef(null);
    const [slide, setSlide] = useState(0);
    const [animating, setAnimating] = useState(false);
    const prevSlide = slide === 0 ? slides.length - 1 : slide - 1;
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [cursorActive, setCursorActive] = useState(false);

    const handleNextClick = () => {
        if (animating) {
            return false;
        }
        setAnimating(true);

        if (slide + 1 >= slides.length) {
            setSlide(0);
        } else {
            setSlide(slide + 1);
        }
        setTimeout(() => {
            setAnimating(false);
        }, 800);
    };

    const handleMouseEnter = () => setCursorActive(true);
    const handleMouseLeave = () => setCursorActive(false);
    const handleMouseMove = (e) => {
        if (!refImages.current) {
            return;
        }
        const rect = refImages.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setCursorPosition({ x, y });
    };

    const drag = useDrag(({ movement }) => {
        if (movement[0] > 0) {
            handleNextClick();
        }
    });

    return (
        <div className="container-studio-intro">
            <div className="layout">
                <section className="page-studio-intro">
                    <header className="studio-intro-header">
                        <h1>We are a creative team with enormous aspirations. We help brands launch and excel.</h1>
                        <p>
                            We are true believers in bold ideas supported by simple and timeless design. Simple always
                            works, no matter how big or small the project is. In our boutique approach, each and every
                            client is unique and their project is tailored to fit their specific needs and the business.
                            Taktic studio becomes an extension of your team, always focusing on what’s best for your
                            brand. We always work as one to achieve a common goal.
                        </p>
                    </header>
                    <div className="studio-intro-slider">
                        <div
                            className="studio-intro-slides"
                            onClick={handleNextClick}
                            onMouseMove={handleMouseMove}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            ref={refImages}
                            {...drag()}
                        >
                            {slides.map(({ image, image_2x }, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    srcSet={`${image} 1x, ${image_2x} 2x`}
                                    alt="Taktic Studio"
                                    className={cx('studio-intro-slide', {
                                        prev: prevSlide === index,
                                        active: slide === index,
                                    })}
                                />
                            ))}
                            <div
                                className={cx('studio-intro-next', { active: cursorActive })}
                                style={{ transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)` }}
                            >
                                <span>Next</span>
                            </div>
                        </div>
                        <ul className={`studio-intro-dots studio-intro-dots-${slides.length}`}>
                            {slides.map((slideData, index) => (
                                <li
                                    key={index}
                                    className={cx('studio-intro-dot', {
                                        active: slide === index,
                                    })}
                                />
                            ))}
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default StudioIntro;
