import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useRouteMatch, Link } from 'react-router-dom';
import anime from 'animejs';

import { useTime } from 'hooks-global';
import Logo from './Logo';

const Menu = ({ linkClick, close, isOpen }) => {
    let startIndex = 0;
    startIndex = useRouteMatch({ path: '/studio/', exact: true, strict: true }) ? 1 : startIndex;
    startIndex = useRouteMatch({ path: '/work/', exact: false, strict: true }) ? 2 : startIndex;
    startIndex = useRouteMatch({ path: '/contact/', exact: true, strict: true }) ? 3 : startIndex;

    const points = [
        { value: '0,0 400,0 400,400 0,400' }, // rectangle
        { value: '0,0 200,200 400,400 0,400' }, // triangle
        { value: '200,0 400,200 200,400 0,200' }, // circle
        { value: '200,0 400,400 200,400 0,400' }, // triangle-2
    ];

    const [prevMenuIndex, setPrevMenuIndex] = useState(startIndex);
    const [menuIndex, setMenuIndex] = useState(startIndex);
    const time = useTime();

    const handleMouseEvent = (index) => () => {
        setPrevMenuIndex(menuIndex);
        setMenuIndex(index);
    };

    useEffect(() => {
        anime({
            targets: '.menu-company-image-path',
            points: [points[prevMenuIndex], points[menuIndex]],
            easing: 'easeInOutCubic',
            duration: 800,
            loop: false,
            autoplay: true,
        });
    }, [prevMenuIndex, menuIndex, points]);

    const isDesktop = window.matchMedia('(min-width: 961px)').matches;

    return (
        <div className={cx('menu-container', { open: isOpen })}>
            <div className="menu-company">
                {isDesktop && <div className="menu-close" onClick={close} />}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" className="menu-company-image">
                    <polygon
                        className="menu-company-image-path"
                        points={points[startIndex].value}
                        fill="#3c4747"
                        fillRule="evenodd"
                    />
                </svg>
                <div className="menu-company-content-box">
                    <div className="menu-company-content">
                        <div className="menu-company-hours">
                            nyc time: {time}
                            <br />
                            studio hours: 8am - 6pm / mon - sat
                            <br />
                            website: 24/7
                        </div>
                        <address className="menu-company-address">
                            <a href="https://g.page/taktic" target="_blank" rel="noopener noreferrer">
                                <span className="menu-company-link">315 West 39TH Street, Studio 609</span>
                                <br />
                                <span className="menu-company-link">New York City, NY 10018</span>
                            </a>
                        </address>
                        <div className="menu-company-phone-email">
                            <a className="menu-company-link" href="tel:+1 212 804 8106">
                                +1 212 804 8106
                            </a>
                            <br />
                            <a className="menu-company-link" href="mailto:info@takticstudio.com">
                                info(at)takticstudio.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="menu-list">
                {!isDesktop && <div className="menu-close" onClick={close} />}
                <div className="menu-logo">
                    <Link to="/" onClick={linkClick('Home')} className="pointer-events-disable">
                        <Logo alt="Taktic Studio" />
                    </Link>
                </div>

                <ul className="menu-items">
                    <li
                        className="menu-item"
                        onMouseEnter={handleMouseEvent(0)}
                        onMouseLeave={handleMouseEvent(startIndex)}
                    >
                        <Link to="/" className="menu-link pointer-events-disable" onClick={linkClick('Home')}>
                            Home
                        </Link>
                    </li>
                    <li
                        className="menu-item"
                        onMouseEnter={handleMouseEvent(1)}
                        onMouseLeave={handleMouseEvent(startIndex)}
                    >
                        <Link to="/work/" className="menu-link pointer-events-disable" onClick={linkClick('Work')}>
                            Work
                        </Link>
                    </li>
                    <li
                        className="menu-item"
                        onMouseEnter={handleMouseEvent(2)}
                        onMouseLeave={handleMouseEvent(startIndex)}
                    >
                        <Link to="/studio/" className="menu-link pointer-events-disable" onClick={linkClick('Studio')}>
                            Studio
                        </Link>
                    </li>
                    <li
                        className="menu-item"
                        onMouseEnter={handleMouseEvent(3)}
                        onMouseLeave={handleMouseEvent(startIndex)}
                    >
                        <Link
                            to="/contact/"
                            className="menu-link pointer-events-disable"
                            onClick={linkClick('Contact')}
                        >
                            Contact
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Menu;
