import React, { useState, useRef, useContext } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { AppContext } from 'features/app';

import NavArrow from '../assets/NavArrow';

const HomeWorks = ({ linkClick }) => {
    const works = useContext(AppContext).works;

    const refImages = useRef(null);
    const [slide, setSlide] = useState(0);
    const prevSlide = slide === 0 ? works.length - 1 : slide - 1;
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [cursorActive, setCursorActive] = useState(false);

    const handleNextClick = () => {
        if (slide + 1 >= works.length) {
            setSlide(0);
        } else {
            setSlide(slide + 1);
        }
    };

    const handleMouseEnter = () => setCursorActive(true);
    const handleMouseLeave = () => setCursorActive(false);
    const handleMouseMove = (e) => {
        if (!refImages.current) {
            return;
        }
        const rect = refImages.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setCursorPosition({ x, y });
    };

    return (
        <div className="container-home-works">
            <div className="page-home-works">
                <div
                    className="home-works-images"
                    onClick={handleNextClick}
                    onMouseMove={handleMouseMove}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={refImages}
                >
                    {works.map(({ home_image, title }, index) => (
                        <img
                            key={index}
                            src={home_image}
                            alt={title}
                            className={cx('home-works-image', { prev: prevSlide === index, active: slide === index })}
                        />
                    ))}
                    <div
                        className={cx('home-works-next', { active: cursorActive })}
                        style={{ transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)` }}
                    >
                        <span>Next</span>
                    </div>
                </div>
                <div className="home-works-content">
                    <div className="home-works-nav-next" onClick={handleNextClick}>
                        <NavArrow />
                    </div>
                    <div className="home-works-counter">
                        <div className="number-box">
                            {works.map((item, index) => (
                                <div
                                    key={index}
                                    className={cx('number', { prev: prevSlide === index, active: slide === index })}
                                >
                                    {`0${index + 1}`}
                                </div>
                            ))}
                        </div>
                        <div className="total">{` / 0${works.length}`}</div>
                    </div>
                    <div className="home-works-slides">
                        {works.map(({ slug, title, description }, index) => (
                            <div
                                key={index}
                                className={cx('home-works-slide', {
                                    prev: prevSlide === index,
                                    active: slide === index,
                                })}
                            >
                                <h6 className="title">{title}</h6>
                                <p className="desc">{description}</p>
                                <Link
                                    to={`/work/${slug}/`}
                                    className="link pointer-events-disable"
                                    onClick={linkClick('Work')}
                                >
                                    View Project
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeWorks;
