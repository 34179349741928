import React, { useContext, useRef, useEffect, useState, Fragment } from 'react';
import cx from 'classnames';

import { AppContext } from 'features/app';
import { StudioCapabilities } from 'features/studio';

const HomeCapabilities = () => {
    const capabilities = useContext(AppContext).capabilities;

    const refScroll = useRef(null);
    const [sliderPercent, setSliderPercent] = useState(0);
    const isDesktop = window.matchMedia('(min-width: 961px)').matches;

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('resize', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('resize', onScroll);
        };
    });

    const onScroll = () => {
        if (!refScroll.current && isDesktop) {
            return;
        }

        const stepsCount = 5;
        const scrollBox = refScroll.current.getBoundingClientRect();
        const scrollProgress = -1 * scrollBox.top;
        const scrollHeight = scrollBox.height;
        const contentHeight = scrollHeight / stepsCount;
        const scrollMax = scrollHeight - contentHeight;

        let percent = 0;
        if (scrollProgress < 0) {
            percent = 0;
        } else if (scrollProgress >= 0 && scrollProgress <= scrollMax) {
            percent = scrollProgress / scrollMax;
        } else {
            percent = 1;
        }

        setSliderPercent(percent * 2); // number of slides (3) - slides shown (1) = 2
    };

    const numbersArray = Array.from(Array(capabilities.length).keys());

    return (
        <div className="container-home-capabilities">
            <div className="page-home-capabilities">
                <div className="home-capabilities-scroll" ref={refScroll} />
                <div className="home-capabilities-content">
                    <div className="content-text">
                        We aim to create a strong, forward-thinking brand identity that empowers our clients to stand
                        out from the crowd. We believe in building long term relationships with a diverse range of
                        clients by bringing eloquent design experiences to life.
                    </div>
                    <div className="content-images">
                        {capabilities.map(({ id, title, image }, imageIndex) => (
                            <img
                                key={id}
                                className={cx('content-image', {
                                    active:
                                        sliderPercent > 0 &&
                                        imageIndex > sliderPercent - 0.5 &&
                                        imageIndex <= sliderPercent + 0.5,
                                })}
                                src={image}
                                alt={title}
                            />
                        ))}
                    </div>
                </div>
                {isDesktop && (
                    <div className="home-capabilities-slides">
                        <div className="home-capabilities-numbers">
                            {numbersArray.map((number) => (
                                <Fragment key={number}>
                                    <span
                                        className={cx({
                                            'home-capabilities-numbers--white': number - 0.5 <= sliderPercent,
                                            'home-capabilities-numbers--gray': number - 0.5 > sliderPercent,
                                        })}
                                    >
                                        {(number + 1).toString().padStart(2, '0')}
                                    </span>
                                    {number < capabilities.length - 1 && (
                                        <span
                                            className={cx({
                                                'home-capabilities-numbers--white': number - 0.5 <= sliderPercent,
                                                'home-capabilities-numbers--gray': number - 0.5 > sliderPercent,
                                            })}
                                        >
                                            /
                                        </span>
                                    )}
                                </Fragment>
                            ))}
                        </div>
                        <div
                            className="home-capabilities-slider"
                            style={{ transform: `translate(-${sliderPercent * 41.7}rem, 0)` }}
                        >
                            {capabilities.map(({ id, title, desc, elements }, listIndex) => (
                                <div className="home-capabilities-slide" key={id}>
                                    <h4 className="title">{title}</h4>
                                    <p className="desc">{desc}</p>
                                    <ul className="list">
                                        {elements.map(({ id, name }) => (
                                            <li key={id}>{name}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {!isDesktop && <StudioCapabilities />}
            </div>
        </div>
    );
};

export default HomeCapabilities;
