import React from 'react';

const Logo = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 22" {...props}>
        <path
            d="M6.92,21.49a4.085,4.085,0,0,1-4.47-4.21V10H.83V7.2H2.46V2.85H6.3V7.2H9.02V10H6.3v7.06a1.1767,1.1767,0,0,0,1.33,1.33H9.02V21.5H6.92Z"
            fill="#f7f7f7"
        />
        <path
            d="M19.58,20.22V21.5h3.75V11.81c0-3.45-2.25-5.2-6.57-5.2-2.57,0-4.05.45-5.56,2.03l2.46,2.29a3.3929,3.3929,0,0,1,2.96-1.21c2.04,0,2.87.71,2.87,2.32v.73H15.85c-3.58,0-5.27,1.92-5.27,4.26a4.5743,4.5743,0,0,0,1.27,3.3,5.4,5.4,0,0,0,3.94,1.3A4.8013,4.8013,0,0,0,19.58,20.22Zm-3.14-4.94h3.05v.79a2.3608,2.3608,0,0,1-.56,1.86,3.1162,3.1162,0,0,1-2.43.71c-1.51,0-2.22-.67-2.22-1.69C14.28,15.9,15.05,15.28,16.44,15.28Z"
            fill="#f7f7f7"
            fillRule="evenodd"
        />
        <path
            d="M34.81,21.49l-3.79-6.13-1.63,1.75v4.38H25.54V1.38h3.85V12.79l5.12-6.02h4.65l-5.5,5.93,5.92,8.78H34.81Z"
            fill="#f7f7f7"
        />
        <path
            d="M46.17,21.49a4.085,4.085,0,0,1-4.47-4.21V10H40.07V7.2H41.7V2.85h3.85V7.2h2.72V10H45.55v7.06a1.1767,1.1767,0,0,0,1.33,1.33h1.39V21.5h-2.1Z"
            fill="#f7f7f7"
        />
        <path
            d="M49.92,2.62a2.3855,2.3855,0,0,0,2.43,2.32,2.359,2.359,0,0,0,2.4-2.32A2.3344,2.3344,0,0,0,52.35.33,2.361,2.361,0,0,0,49.92,2.62Zm.51,4.16V21.49h3.85V6.78Z"
            fill="#f7f7f7"
            fillRule="evenodd"
        />
        <path
            d="M63.36,21.66c-3.08,0-6.89-1.58-6.89-7.54s3.82-7.51,6.89-7.51a6.7131,6.7131,0,0,1,5.09,1.98l-2.6,2.49a3.1742,3.1742,0,0,0-2.49-1.16,2.7137,2.7137,0,0,0-2.19.96,4.8469,4.8469,0,0,0-.86,3.25,4.9382,4.9382,0,0,0,.86,3.28,2.7137,2.7137,0,0,0,2.19.96,3.1742,3.1742,0,0,0,2.49-1.16l2.6,2.46A6.7948,6.7948,0,0,1,63.36,21.66Z"
            fill="#f7f7f7"
        />
        <path
            d="M82.47,21.66c-2.43,0-4.62-.25-6.57-2.12l2.52-2.4a5.7774,5.7774,0,0,0,4.11,1.38c1.33,0,2.72-.42,2.72-1.53,0-.73-.41-1.24-1.63-1.36l-2.43-.23c-2.78-.25-4.5-1.41-4.5-4.12,0-3.05,2.81-4.69,5.95-4.69,2.4,0,4.41.4,5.89,1.72L86.16,10.6a5.5609,5.5609,0,0,0-3.58-.99c-1.54,0-2.19.68-2.19,1.41,0,.54.24,1.16,1.6,1.27l2.43.23c3.05.28,4.59,1.84,4.59,4.32C89.01,20.11,86.11,21.66,82.47,21.66Z"
            fill="#f7f7f7"
        />
        <path
            d="M96.01,21.49a4.085,4.085,0,0,1-4.47-4.21V10H89.91V7.2h1.63V2.85h3.85V7.2h2.72V10H95.39v7.06a1.1767,1.1767,0,0,0,1.33,1.33h1.39V21.5h-2.1Z"
            fill="#f7f7f7"
        />
        <path
            d="M109.15,21.49V20.13a5.41,5.41,0,0,1-3.85,1.53,5.14,5.14,0,0,1-3.64-1.3,5.324,5.324,0,0,1-1.57-4.18V6.78h3.85v8.9a2.4129,2.4129,0,0,0,2.54,2.68,2.4391,2.4391,0,0,0,2.57-2.68V6.78h3.85V21.49Z"
            fill="#f7f7f7"
        />
        <path
            d="M124.41,20.11v1.38h3.76V1.38h-3.85V8.07a4.7483,4.7483,0,0,0-3.76-1.47,5.5219,5.5219,0,0,0-3.76,1.33c-1.54,1.47-1.57,3.99-1.57,6.19s.03,4.74,1.57,6.21a5.6024,5.6024,0,0,0,3.79,1.33A4.7261,4.7261,0,0,0,124.41,20.11Zm-5.32-5.99c0-2.4.29-4.21,2.6-4.21s2.63,1.81,2.63,4.21-.32,4.24-2.63,4.24C119.39,18.36,119.09,16.52,119.09,14.12Z"
            fill="#f7f7f7"
            fillRule="evenodd"
        />
        <path
            d="M130.06,2.62a2.4169,2.4169,0,0,0,4.83,0,2.3344,2.3344,0,0,0-2.4-2.29A2.361,2.361,0,0,0,130.06,2.62Zm.5,4.16V21.49h3.85V6.78Z"
            fill="#f7f7f7"
            fillRule="evenodd"
        />
        <path
            d="M142.64,21.66a6.5414,6.5414,0,0,0,4.79-1.81c1.39-1.38,1.74-3.05,1.75-5.73,0-2.66-.36-4.33-1.75-5.71a7.2035,7.2035,0,0,0-9.55,0c-1.4,1.39-1.75,3.06-1.75,5.71,0,2.68.36,4.35,1.75,5.73A6.4467,6.4467,0,0,0,142.64,21.66Zm0-11.75a2.51,2.51,0,0,1,1.8.65c.8.76.89,2.04.89,3.56,0,1.53-.09,2.83-.89,3.59a2.4929,2.4929,0,0,1-1.8.65,2.4434,2.4434,0,0,1-1.78-.65c-.8-.76-.89-2.06-.89-3.59s.09-2.8.89-3.56A2.46,2.46,0,0,1,142.64,9.91Z"
            fill="#f7f7f7"
            fillRule="evenodd"
        />
    </svg>
);

export default Logo;
