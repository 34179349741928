import React from 'react';
import he from 'he';

const SingleContent = ({ work: { content, tags, url_name, url_link } }) => {
    return (
        <div className="container-works-single-content">
            <div className="layout">
                <div className="page-works-single-content">
                    <div
                        className="works-single-content-text"
                        dangerouslySetInnerHTML={{ __html: he.decode(content) }}
                    />
                    <div className="works-single-content-meta">
                        <ul className="works-single-content-tags">
                            {tags.map((tag, tagIndex) => (
                                <li key={tagIndex}>{tag}</li>
                            ))}
                        </ul>
                        <div className="works-single-content-link">
                            <a href={url_link} target="_blank" rel="noopener noreferrer">
                                {url_name}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleContent;
