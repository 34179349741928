import React, { useContext, useState, useEffect } from 'react';
import cx from 'classnames';

import { useTime } from 'hooks-global';
import { AppContext } from 'features/app';

const Footer = () => {
    const facts = useContext(AppContext).facts;
    const time = useTime();
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const nextFact = () => {
            let index = activeIndex + 1;
            if (index > facts.length - 1) {
                index = 0;
            }
            setActiveIndex(index);
        };
        const timeout = setTimeout(nextFact, 4000);
        return () => {
            clearTimeout(timeout);
        };
    });

    return (
        <footer className="container-footer">
            <div className="layout">
                <div className="page-footer">
                    <div className="footer-facts">
                        <div className="footer-facts-slider">
                            {facts.map(({ id, text }, index) => (
                                <div
                                    className={cx('footer-fact', { 'footer-fact--active': index === activeIndex })}
                                    key={id}
                                >
                                    <div className="footer-fact-title">Studio Fact Nº {index + 1}</div>
                                    <div className="footer-fact-text">{text}</div>
                                </div>
                            ))}
                        </div>
                        <a className="footer-link" href="mailto:info@takticstudio.com">
                            Send us a message
                        </a>
                    </div>
                    <h3 className="footer-logo">
                        <img src={require('../assets/logo.svg')} alt="taktic studio" />
                    </h3>
                    <div className="footer-company">
                        <address className="footer-company-address">
                            <a href="https://g.page/taktic" target="_blank" rel="noopener noreferrer">
                                <span className="footer-link">315 West 39TH Street, Studio 609</span>
                                <br />
                                <span className="footer-link">New York City, NY 10018</span>
                            </a>
                        </address>
                        <div className="footer-company-contact">
                            <a className="footer-link" href="tel:+1 212 804 8106">
                                +1 212 804 8106
                            </a>
                            <br />
                            <a className="footer-link" href="mailto:info@takticstudio.com">
                                info(at)takticstudio.com
                            </a>
                        </div>
                        <div className="footer-company-hours">
                            nyc time: {time}
                            <br />
                            studio hours: 8am - 6pm / mon - sat
                            <br />
                            website: 24/7
                        </div>
                        <div className="footer-company-social">
                            <a
                                href="https://www.instagram.com/taktic_studio/"
                                className="footer-link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                instagram
                            </a>
                        </div>
                    </div>
                    <p className="footer-copyright">
                        All Rights Reserved &copy; 2019
                        <br className="br-mobile" /> Taktic Studio.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
