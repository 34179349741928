import React from 'react';
import cx from 'classnames';

const SingleGrid = ({ work }) => (
    <div className="container-works-single-grid">
        <div className="layout">
            <div className="page-works-single-grid">
                {work.grid.map(({ type, images }, itemIndex) => (
                    <div className={cx('works-single-grid-row', `works-single-grid-row-${+type}`)} key={itemIndex}>
                        {images.map((image, imageIndex) => (
                            <img key={imageIndex} className="works-single-image" src={image} alt={work.title} />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default SingleGrid;
