import React from 'react';

const NavArrow = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15" {...props}>
        <path
            d="M11.4681,1.18409l5.3822,5.47858H0V8.338H16.8059L11.4681,13.816,12.6319,15l7.368-7.5L12.6319.00061Z"
            fill="#cfd1d1"
            fillRule="evenodd"
        />
    </svg>
);

export default NavArrow;
