import React, { useEffect, useRef, useState, useContext } from 'react';
import cx from 'classnames';

import { AppContext } from 'features/app';

const StudioAwards = () => {
    const awards = useContext(AppContext).awards;

    const refContainer = useRef(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        document.addEventListener('resize', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
            document.removeEventListener('resize', onScroll);
        };
    });

    const onScroll = () => {
        const vh = parseFloat(document.documentElement.style.getPropertyValue('--vh'));
        if (!refContainer.current || Number.isNaN(vh)) {
            return;
        }

        const containerBox = refContainer.current.getBoundingClientRect();
        if (containerBox.top + containerBox.height < 150 * vh) {
            setVisible(true);
        }
    };

    return (
        <div className="container-studio-awards">
            <div className="layout">
                <section className="page-studio-awards">
                    <header className="studio-awards-header">
                        <h6>Studio Staff</h6>
                        <h3>
                            Awards &<br />
                            Recognitions
                        </h3>
                        <p>
                            We’ve always strived and thrived at providing industry-leading services to our respected
                            clients. We are proud to have been recognized by a variety of design organizations for our
                            work in website design, website development, branding, packaging, and other creative
                            services.
                        </p>
                    </header>
                    <ul className={cx('studio-awards-list', { visible })} ref={refContainer}>
                        {awards.map(({ logo, logo_2x, title }) => (
                            <li className="studio-awards-item" key={title}>
                                <img src={logo} srcSet={`${logo} 1x, ${logo_2x} 2x`} alt={title} />
                            </li>
                        ))}
                    </ul>
                </section>
            </div>
        </div>
    );
};

export default StudioAwards;
