import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from 'features/app';

const SingleNext = ({ work: { slug }, linkClick }) => {
    const works = useContext(AppContext).works;
    const currentIndex = works.findIndex(({ slug: itemSlug }) => slug === itemSlug);
    let nextIndex = currentIndex + 1;
    if (nextIndex > works.length - 1) {
        nextIndex = 0;
    }
    const nextWork = works[nextIndex];

    return (
        <div className="container-works-single-next">
            <Link
                to={`/work/${nextWork.slug}/`}
                className="page-works-single-next pointer-events-disable"
                onClick={linkClick('Work')}
            >
                <img className="works-single-next-image" src={nextWork.next_image} alt={nextWork.title} />
                <span className="works-single-next-cover" />
                <span className="works-single-next-text">Next Project</span>
                <span className="works-single-next-name">{nextWork.title}</span>
            </Link>
        </div>
    );
};

export default SingleNext;
