import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { AppContext } from 'features/app';
import Arrow from '../assets/Arrow';

const StudioProcess = () => {
    const processItems = useContext(AppContext).process;
    const [active, setActive] = useState(null);

    const clickItem = (index) => () => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    };

    return (
        <div className="container-studio-process">
            <div className="layout">
                <section className="page-studio-process">
                    <header className="studio-process-header">
                        <h3>Our Process</h3>
                        <p>
                            We are true believers in bold ideas supported by simple and timeless design.
                            <br />
                            Simple always works, no matter how big or small the project is.
                        </p>
                    </header>
                    <ol className="studio-process-list">
                        {processItems.map(({ title, description }, index) => (
                            <li className={cx('studio-process-item', { active: active === index })} key={index}>
                                <div className="more" onClick={clickItem(index)}>
                                    <Arrow />
                                </div>
                                <h4>{title}</h4>
                                <p>{description}</p>
                            </li>
                        ))}
                    </ol>
                </section>
            </div>
        </div>
    );
};

export default StudioProcess;
